@import '../../../styles/mixins.scss';

.container {
  display: block;
}

.header {
  display: flex;
  /* padding: 24px; */
  padding: 14px 22px;
  border-radius: 2px;
  background: #e0eafa;
  align-content: center;

  & h3 {
    display: block;
    font-size: 16px;
    line-height: 25px;
    margin: 0;
  }
}

.question {
  color: $black;
  font-feature-settings: "clig" off, "liga" off;
  font-family: 'AvenirNextLTPro-Regular';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
}

.body {
  background-color: $white;
  padding: 14px 22px;
  margin-top: 14px;
  padding-bottom: 8px;
}

.title {
  color: $black;
  font-feature-settings: "clig" off, "liga" off;
  /* Desktop/Caption */
  font-family: 'AvenirNextLTPro-Regular';
  font-size: 9px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
  display: none;
}

.content {
  color: $black;
  font-family: 'AvenirNextLTPro-Regular';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  margin-top: 16px;
  border-radius: 2px;
}

.citations {
  display: flex;
  flex-wrap: wrap;
  column-gap: 14px;
  margin-top: 4px;
}

.citation {
  color: $black;
  font-feature-settings: "clig" off, "liga" off;
  display: flex;
  font-family: 'AvenirNextLTPro-Regular';
  gap: 4px;
  font-size: 9px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  width: fit-content;
  margin: 0;
  flex-direction: row;

  & :first-child {
    color: $blue;
  }

  .citeBtn {
    font-size: 9px;
    font-style: normal;
    font-weight: 500;
    height: 22px;
  }
}

.buttons {
  display: flex;
  gap: 10px;
}

.actions {
  display: flex;
  justify-content: space-between;
}

.btn {
  & svg {
    fill: none;
    width: 22px;
    height: 22px;
  }
}

.like {
  opacity: 0.5;
}

.active {
  opacity: 1;
}

.unlikeBtn {
  & svg {
    transform: rotate(-180deg);
  }
}