@import '../../styles/mixins.scss';

.modal {
  top: 50%;
  right: auto;
  bottom: auto;
  left: 50%;
  transform: translate(-50%, -50%);
  background: $grey3;
  width: 50%;
  position: absolute;
  min-width: 500px;
  padding: 20px;
  outline: none;
  z-index: 999;

  @media (max-width: 768px) {
      width: 90%;
      min-width: 65%;
      height: 90%;
  }
}

.close-button {
  position: absolute;
  background: none;
  color: $blue;
  right: 32px;
  top: 30px;

  @media (max-width: 768px) {
    right: 0;
    top: 0;
  } 

  &:before {
    display: inline-block;
    content: "\00d7";
    font-size: 30px;
  }
  
}