@import '../../styles/mixins.scss';

.btn {
    border: none;
    display: flex;
    font-size: 0.875rem;
    font-family: 'SpaceMono-Regular';
    width: fit-content;
    height: 40px;
    padding: 10px 32px;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    cursor: pointer;

    &.primary {
        background-color: $white;
        color: $black;

        &:hover {
            box-shadow: inset 0em 0em 0em 10em rgba(0, 0, 0, 0.15);
        }
    }

    &.secondary {
        background-color: $blue;
        color: $white;
        font-family: 'AvenirNextLTPro-Regular';

        &:hover {
            background: $black;
            box-shadow: inset 0em 0em 0em 10em rgba(0, 0, 0, 0.15);
        }
    }

    &.tertiary {
        background-color: $black;
        color: $white;

        &:hover {
            box-shadow: inset 0 0 0 10em rgba(255, 255, 255, 0.3);
        }
    }

    &.tertiary {
        background-color: $black;
        color: $white;

        &:hover {
            box-shadow: inset 0 0 0 10em rgba(255, 255, 255, 0.3);
        }
    }
    &.tertiaryOutline {
        background-color: $black;
        color: $white;
        border: 1px solid $white;

        &:hover {
            box-shadow: inset 0 0 0 10em rgba(255, 255, 255, 0.3);
        }
    }
    &.quaternary {
      background-color: $white;
      color: $black;
      font-family: 'AvenirNextLTPro-Regular';

        &:hover {
          background: $blue;
          color: $white;
          box-shadow: inset 0em 0em 0em 10em rgba(0, 0, 0, 0.15);
        }
    }
    &:disabled {
        opacity: 0.5;

        &:hover {
            filter: none
        }
    }
}