@import '../../../styles/mixins.scss';

.wrapper {
  flex-wrap: wrap;
  display: flex;
  vertical-align: top;
  height: 100%;
}

.expand {
  position: absolute;
  right: 27px;
  top: 24px;
  cursor: pointer;
}

.my-saved-wrapper {
  margin: 50px;
}

.item-template {
  position: relative;
  border-radius: 5px;
  padding: 8px 11px 40px 11px;
  transition: background-color 0.15s ease-out 100ms;
  min-height: 283px;
  color: #fff;

  @media (max-width: 1200px) {
    min-height: 250px;
  }

  p {
    margin-top: 20px;
    margin-bottom: 14px;
  }


  &:hover {
    background: $blue;
    color: #fff;
    ;
  }

  .image {
    width: 100%;
  }

  @media (max-width: 768px) {
    background: #fff;
  }

  span {
    font-size: 1rem;
    line-height: 170%;
  }
}

.share {
  position: absolute;
  right: 100px;
  top: 158px;
  display: flex;
  justify-content: space-between;

  .share-text {
    min-width: 100px
  }

  button {
    background: none;
    color: $blue;
    width: 160px;
    display: flex;
    justify-content: space-between;

    @media (max-width: 768px) {
      width: auto;
      padding: 0;

      span {
        display: none;
      }
    }
  }
}

.item {
  width: 100%;
  min-height: 500px;
  border: none;
}

.icon {
  margin: 0 5px;
  cursor: pointer;
}

.action-buttons {
  position: absolute;
  bottom: 5px;
  left: 13px;
}

.image {
  height: 191px;
  width: 100%;
  background-position: center;
  background-size: cover;
}

.button {
  padding: 0;
  background-color: transparent;
  border: 0;
  display: inline;
}