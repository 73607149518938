@import '../../styles/mixins.scss';


.btn {
    border: none;
    display: inline-block;
    font-size: 0.875rem;
    font-family: 'SpaceMono-Regular';
    width: fit-content;
    max-width: 250px;
    height: 40px;
    padding: 10px 32px;
    border-radius: 2px;
    cursor: pointer;
    background: none;
    border: none;
    padding: 0;
    text-decoration: none;
    text-overflow: ellipsis;
    text-wrap: nowrap;
    overflow: hidden;


    &:disabled {
        opacity: 0.5;

        &:hover {
            filter: none
        }
    }

    &:hover {
        text-decoration: underline;
    }
}