.quarter {
  width: calc(25% - 20px);
  margin: 10px;

  @media screen and (max-width: 768px) {
    width: calc(50% - 30px);
  }

  @media screen and (max-width: 768px) {
    width: calc(100% - 30px);
  }
}

.half {
  width: calc(50% - 10px);
  margin: 10px;

  &:first-of-type, &:nth-of-type(3) {
    margin-left: 0;
  }

  &:nth-of-type(2), &:nth-of-type(4) {
    margin-right: 0;
  }
  // width: 50%;
  min-width: calc(50% - 20px) !important;

  @media screen and (max-width: 768px) {
    width: 100%;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    margin: 10px 0;
  }
}
