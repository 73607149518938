@import '../../styles/mixins.scss';

.card-wrapper {
  width: 100%;
  margin-bottom: 28px;
  position: relative;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.inside-wrapper {
  background: #fff;
  padding: 13px 15px;
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  border-radius: 2px;
}

.question {
  font-family: 'SpaceMono-Regular';
  text-transform: uppercase;
  font-size: 0.625rem;
  margin-bottom: 12px;
  letter-spacing: 1.3px;
  line-height: 18px;
  margin-right: 32px;

  @media (max-width: 768px) {
    padding: 0;
  }
}

.answer {
  line-height: 170%;

  @media (max-width: 768px) {
    display: none;
  }
}

.expand-conversation {
  position: absolute;
  top: 9px;
  right: 27px;
}

.active {
  z-index: 70;
}

.icon {
  margin: 0px 2px;
}

.first {
  margin: 0px 2px 0px 0px;
}

.icons {
  padding: 10px 8px 0 7px;
}

.timestamp {
  font-size: 0.563rem;
  color: $blue;
  padding-top: 5px;
}

.icon {
  padding: 0 5px;
  margin: 0;
}

.first {
  padding: 0px 5px 0px 0px;
  margin: 0;
}

.answer-mobile {
  @media (max-width: 768px) {
    display: block;
  }

  display: none;
}