@font-face {
    font-family: 'SpaceMono-Regular';
    src: local('SpaceMono-Regular'),
         url('./SpaceMono-Regular.ttf') format('woff'),
         url('./SpaceMono-Regular.ttf') format('woff2');
}

@font-face {
    font-family: 'AvenirNextLTPro-Regular';
    src: local('AvenirNextLTPro-Regular'),
         url('./AvenirNextLTPro-Regular.otf') format('woff'),
         url('./AvenirNextLTPro-Regular.otf') format('woff2');
}