@import "./styles/mixins.scss";
@import "./styles/global.scss";
@import "./styles/forms.css";
@import "./styles/hamburger.css";
@import "./fonts/fonts.css";

/* Global Stylesheet */

body {
  // Uncomment to test bg blur
  // background-image: url('https://imageio.forbes.com/specials-images/imageserve/675172642/pura-ulun-danu-bratan-temple-in-Bali-/960x0.jpg?format=jpg&width=960');
  margin: 0;
  width: 100%;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left top;

  @media (max-width: 768px) {
    background-attachment: fixed;
  }
}

#root {
  display: flex;
  align-items: stretch;
  height: 100%;
  flex-direction: column;
  background: rgba(4, 41, 71, 0.85);
  backdrop-filter: blur(8px);

}

@media (min-width: 768px) {
  .mobile-nav {
    display: none;
  }
}