@import '../../styles/mixins.scss';

.back-to-exp {
  background: none;
  color: #fff;
  width: 176px;
  display: flex;
  justify-content: space-between;
  float: right;
  margin-top: 16px;
  margin-right: 10px;
  position: absolute;
  right: 10px;
  top: 120px;

  &::before {
    content: "\2039";
    font-size: 25px;
    margin-right: 5px;
  }

  @media (max-width: 768px) {
    width: auto;
    padding: 0;
    top: 102px;

    span {
      display: none;
    }
  }
}

.title {
  font-size: 1.5rem;
  line-height: 140%;
  margin: 0;
  margin-left: 7px;
  margin-bottom: 25px;
  color: #fff;
}

.wrapper {
  overflow-y: auto;
  height: calc(100% - 66px);

  @media (max-width: 768px) {
    height: 100%;
  }
}