.wrapper {
  background: #fff;
  position: relative;
  bottom: 0;
  height: 100%;
  width: 100%;
  border-radius: 20px 20px 20px 0;
  font-size: 1rem;
  line-height: 150%;
}

.chat-container {
  position: relative;
  display: flex;
  height: 80%;
  width: 100%;
  margin-top: 2rem;
  overflow-y: auto;
}

.close {
  position: absolute;
  right: 20px;
  top: 20px;
}

.input {
  position: absolute;
  bottom: 2rem;
  padding: 0 20px;
  width: calc(100% - 40px);
}

.conversation-wrapper {
  width: 100%;
  display: flex;
  align-items: flex-end;
  margin-top: auto;
}

.conversation {
  display: flex;
  width: 100%;
  align-items: flex-end;
  flex-direction: column;
  padding: 2rem;
  height: 100%;
  overflow-y: auto;
  align-items: flex-end;
}

.question {
  background: #E6EFF8;
  display: flex;
  border-radius: 8px;
  padding: 10px;
  margin: 10px 0px 10px 30px;
}

.answer {
  padding: 10px;
  width: 100%;
  display: flex;
  margin: 10px 0;
}

.followup-container {
  width: 100%;
  display: flex;
  // word-wrap: break-word;
  flex-wrap: wrap;
}

.followup {
  background: #E6EFF8;
  display: flex;
  border-radius: 8px;
  margin: 0 5px 5px 0;
  text-align: left;
  font-size: 0.75rem;
  line-height: 150%;
  height: auto;
  padding: 10px;
}

.convert {
  position: absolute;
  right: 10px
}