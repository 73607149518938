/*********************
  COLORS
*********************/
$white: #fff;
$black: #000;
$black-secondary: #010307;
$dark-gray: #313131;
$grey2: #010307;
$grey3: #EDEDED;
$nav-grey: #010307;
$light-blue: #E0EAFA;
$blue: #1960AE;
$blue-secondary: #006EE8;
$blue-tertiary: #1c6dc3;
$mobile-nav-grey: #A7A7A7;

/*********************
  FONTS
*********************/

@mixin lato-light {
    font-family: 'Lato', sans-serif;
    font-weight: 300;
    -webkit-font-smoothing: antialiased;
}

@mixin lato-regular {
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
}

@mixin lato-bold {
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    -webkit-font-smoothing: antialiased;
}
