@import '../../styles/mixins.scss';

.question {
  background: $light-blue;
  border-radius: 2px;
  padding: 20px;
  margin: 10px 0;
}

.answer {
  background: #fff;
  border-radius: 2px;
  padding: 20px;
  margin: 10px 0;
  line-height: 170%;
  position: relative;
  padding-bottom: 40px;
}

.conversation-container {
  // margin-bottom: 100px;
  overflow-y: auto;
  height: calc(100% - 58px);

  ::-moz-scrollbar {
    width: 12px;
  }

  @media (max-width: 767px) {
    height: calc(100dvh - 58px);
    overflow-y: visible;
  }
}

.chat-item {
  display: flex;
  //align-items: end;
  flex-direction: column;
  row-gap: 14px;
}

.chat-item-container {
  display: flex;
  flex-direction: column;
}

.view {
  color: $blue;
  justify-content: end;
  display: flex;
  font-size: 0.6rem;
  font-family: 'SpaceMono-Regular';
  text-transform: uppercase;
  align-items: center;
  margin-right: 20px;
  column-gap: 8px;
}

.answers-icon {
  padding: 2px;
}

.view-tile {
  padding: 5px;
  cursor: pointer;

  & svg {
    height: 18px;
    width: 18px;
  }
}

.view-full {
  padding: 5px;
  font-family: 'SpaceMono-Regular';
  font-size: 0.875rem;
  text-transform: uppercase;
  cursor: pointer;

  svg {
    height: 14px;
    width: 14px;
  }
}

.search-box {
  position: sticky;
  bottom: 0;
  width: 100%;
  margin-top: 10px;

  @media (max-width: 767px) {
    position: fixed;
    left: 0;
    border-top: 1px solid #ccc;
    background-color: white;
  }
}

.answers-footer {
  position: absolute;
  right: 10px;
  bottom: 0;
}

.tabs {
  position: absolute;
  left: 56px;
  top: 74px;
  display: none;

  @media (max-width: 768px) {
    display: flex;
  }
}

.viewText {
  letter-spacing: 1.3px;
  color: #fff;
}

.tile-divider {
  width: 1px;
  background: #fff;
  height: 27px;
}

.footer {
  position: absolute;
  bottom: 20px;
  width: calc(100% - 60px);
  padding-right: 20px;
}