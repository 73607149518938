@import '../../styles/mixins.scss';

.nav {
  font-size: 0.625rem;
  text-transform: uppercase;
  display: flex;
  font-family: 'SpaceMono-Regular';
  margin: 0;

  @media (max-width: 768px) {
    display: none;
  }

  li {
    display: flex;
    padding: 5px 10px;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -ms-user-select: none;
    letter-spacing: 2px;
    user-select: none;
  }

  .item {
    text-decoration: none;
    color: #000;
    cursor: pointer;

    &.active {
      font-weight: bold;
    }

    &:hover {
      font-weight: bold;
    }
  }
}

.nav-wrapper {
  background: #fff;
  height: 24px;

  @media (max-width: 768px) {
    display: none;
  }
}

.icon {
  margin: 2px 6px;
}

.adjusted-icon {
  margin: 1px 6px;
}

a {
  display: flex;
}