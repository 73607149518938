.wrapper {
  height: calc(100% - 40px);
}

.scroll-left-right {
  width: auto;
  display: flex;
}

.slider-wrapper {
  display: flex;
  flex-direction: row;
}

.arrows-button {
  flex-direction: column;
}

.slider {
  max-width: 600px;
}

.pagination {
  float: right;
}

.rotate {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.bottom {
  display: flex;
  justify-content: space-between;

  .icons {
    width: 100px;
    display: flex;
    column-gap: 8px;
    padding-left: 8px;
  }
}

.btn {
  & svg {
    width: 27px;
    height: 27px;
    fill: none;
  }
}

.btnDisabled {
  opacity: 0.4;
}