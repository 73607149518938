.button-wrapper {
  height: 40px;
  font-size: 0.875rem;
  font-family: 'AvenirNextLTPro-Regular';
  display: flex;
  float: left;
  
  img {
    margin-top: -2px;
    width: 15px;
  }
}

.full {
  width: 100%;
  display: flex;
  justify-content: center;
}

.half {
  width: calc(50% - 20px);
  display: flex;
  justify-content: center;
}

.right {
  order: 1;
}
  
button {
  border: none;
  height: 40px;
  font-size: 0.875rem;
  font-family: 'AvenirNextLTPro-Regular';
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0 20px;
}

.primary {
  background: white;
  color: black;
}

.secondary {
  background: #006EE8;
  color: white;
}

.tertiary {
  font-family: 'SpaceMono-Regular';
  text-transform: uppercase;
  background: black;
  color: white;
}

.collapse {
  bottom: 18px;
  width: 135px;
  height: 32px;
  font-weight: bold;
  position: absolute;
  left: calc(50% - 58px);
  border-radius: 10px;
  font-family: 'SpaceMono-Regular';
  text-transform: uppercase;
  color: #fff;
  background: rgb(2,0,36);
  background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(103,156,216,1) 0%, rgba(130,176,228,1) 100%);
  background-repeat: no-repeat;

  &:after {
    content:"\2039";
    transform: rotate(90deg);
    font-size: 1.5rem;
    margin-left: 5px;
  }
}

.clear {
  font-family: 'AvenirNextLTPro-Regular';
  background: none;
  color: #1960AE;
}