@import '../../../styles/mixins.scss';

.videoViewer {
  width: 100%;
  height: 100%;
  display: flex !important;
  justify-content: center;
  align-items: center;

  embed {
    height: 100%;
  }
}

.loading {
  display: none !important;
}