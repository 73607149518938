.wrapper {
    padding: 20px 37px;

    @media (max-width: 768px) {
      padding:5px;
    }
}
.image {
    width: 100%;
    display: none;
}

.shared-items-container {
    display: flex;
}

.shared-item {
    display: flex;
    min-width: 115px;
    height: 205px;
    padding: 5px;
    flex-direction: column;
    background: #fff;
    margin: 0 5px;
    position: relative;
}

.question {
    font-family: 'SpaceMono-Regular';
    text-transform: uppercase;
    font-size: 0.563rem;
    font-weight: bold;
    margin-bottom: 10px;
}

.answer {
    font-size: 0.625rem;
    line-height: 160%;
}

.remove {
    position: absolute;
    top: -5px;
    right: -5px;
}

.scrollContainer {
    padding: 0px 40px 0 20px;
}