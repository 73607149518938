@import '../../../styles/mixins.scss';

.container {
  display: flex;
  gap: 12px;
}

.chat {
  flex: 0 0 auto;

  & svg {
    width: 39px;
    height: 39px;
  }
}


.controls {
  flex-grow: 1;
  display: flex;
  gap: 8px;
  border-bottom: 1px solid #fff;

  @media (max-width: 767px) {
    padding: 10px 10px 0;
  }
}


.submit {
  & svg {
    width: 33px;
    height: 33px;
  }
}

.voice {
  & svg {
    width: 34px;
    height: 34px;
  }
}

.input {
  flex-grow: 1;
  border: none;
  background: transparent;
  font-family: 'AvenirNextLTPro-Regular';
  font-size: 24px;
  margin: 0;
  outline: none;
  position: relative;

  &::placeholder {
    color: #fff !important;
  }

  @media (max-width: 320px) {
    width: 90%;
    flex-grow: 0;
  }

  &::placeholder {
    color: $nav-grey;
    opacity: 0.3;
  }
}

.buttons {
  display: flex;
  flex: 0 0 auto;
  column-gap: 11px;

  @media only screen and (orientation: portrait) {
    column-gap: 8px;
  }
}

.chatbot-input {
  &::placeholder {
    color: black !important;
    opacity: 0.7;
  }
}

.chatbot-controls {
  border-bottom: 1px solid $blue-secondary;
}