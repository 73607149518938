@import '../../../styles/mixins.scss';

.container {
  display: flex;
  flex-direction: column;
  row-gap: 50px;
}

.swipe {
  width: 100%;
  order: 1;
}

.search {
  width: 100%;
  order: 2;
}

.swipeSwapped {
  order: 2;
}

.searchSwapped {
  order: 1;
}

.chatbot {
  color: green;
}