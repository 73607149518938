.toast {
  min-height: 48px;
  background: white;
  border-radius: 4px;
  padding: 2px 30px 2px 10px;
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-weight: bold;
  right: 100px;
  margin-right: 50px;
}

.success {
  color: black;
  font-size: 0.875rem;
  font-family: "AvenirNextLTPro-Regular";
  font-weight: 100;
}

.information {
  // To be implemented generic info popup if needed
}

.error {
  // To be implemented error info popup if needed
}

.message {
  width: 100%;
  margin-left: 10px;
  margin-top: 3px;
  min-width: 200px;
}