@import '../../styles/mixins.scss';

.conversation-wrapper {
    width: 50%;
  
    @media screen and (max-width: 768px) {
      width: 100%;
    }
    
    .question-answer {
      padding: 10px;
      background: #fff;
      border-radius: 2px;
      margin: 10px 0 0;
      width: 100%;
      position: relative;
      
      img {
        cursor: pointer;
      }

      @media screen and (max-width: 768px) {
          width: calc(100% - 20px);
        }
    }
  
    .question {
      font-family: 'SpaceMono-Regular';
      text-transform: uppercase;
      font-size: 0.625rem;
      margin-bottom: 20px;
      margin-right: 26px;
    }
  
    .answer {
      margin-bottom: 10px;
      line-height: 170%;
    }
  
    .timestamp {
      font-size: 0.563rem;
      color: $blue;
    }
  }

  .item {
    min-height: 600px;
    width: 100%;
  }
  
  .expand-conversation {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .delete-conversation {
    position: absolute;
    bottom: 10px;
    right: 10px;
  }

  .icon {
    margin: 0px 5px;
  }

  .icons {
    display: flex;
    width: auto;
  }

  .button {
    background: transparent;
    padding: 0 5px;

    &:first-of-type {
      padding-left: 0;
    }
  }