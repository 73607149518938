@import '../../../styles/mixins.scss';

.pdfViewer {
  width: 100%;
  height: 100%;

  & embed {
    height: 100%;
    pdf-viewer {
      display: none;
    }
  }


  :global(#toolbar){
    viewer-toolbar {
      display: none;
    }
  }

  .iframe {
    display: block;
  }
}

.loading {
  display: none !important;
}