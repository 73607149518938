@import '../../styles/mixins.scss';

.nav {
  display: flex;
  list-style: none;
  justify-content: center;
  padding: 0;
  font-family: 'SpaceMono-Regular';
  text-transform: uppercase;
  position: relative;
  z-index: 999;

  li {
    font-size: 0.625rem;
    cursor: pointer;
    font-weight: bold;
  }

  .item {
    text-decoration: none;
    // color: $mobile-nav-grey;
    color: #000;
    padding: 20px;
  }

  .extra-menu {
    background: #000;
    position: absolute;
    left: -22px;
    top: 10px;
    width:250px;
  }
}

.extra-menu-ul {
  color: #fff;
  list-style-type: none;
  display: flex;
  padding: 0;
  justify-content: center;
  margin: 10px 0;
  
  .sub-item {
    color: #fff;
    width: 100%;
    display: flex;
    justify-content: center;
    text-decoration: none;
    padding: 20px 0;
    width: 186px;

    &:hover {
      background: #161616;
  }
  }
}

.nav-wrapper {
  display: flex;
  margin-left: 20px;
  margin-top: 20px;
}

.nav-inside-wrapper {
  position: relative;
  width: 300px;
  display: flex;
}

.menu {
  float: left;
}

.bar1 {
  width: 20px;
  height: 2px;
  background-color: #fff;
  margin: 6px 0;
}

.bar2 {
  width: 10px;
  height: 2px;
  background-color: #fff;
  margin: 6px 0;
}

.black {
  background-color: #000 !important;
}

.icon {
  margin-right: 10px;
}