@import '../../styles/mixins.scss';

.wrapper {
  flex-wrap: wrap;
  display: flex;
  vertical-align: top;
  height: 85%;
}

.expand {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}

.my-saved-wrapper {
  margin: 30px 50px 50px 50px;
  height: 75%;

  @media (max-width: 768px) {
    margin: 0;
  }
}

.item-template {
  position: relative;

  .image {
    width: 100%;
  }

  span {
    font-size: 1rem;
    line-height: 170%;
  }
}

.back-to-exp {
  background: none;
  color: $blue;
  width: 155px;
  display: flex;
  justify-content: space-between;
  float: right;
  margin-left: 18px;
  padding-right: 0;

  &::before {
    content: "\2039";
    font-size: 25px;
    margin-right: 5px;
  }

  @media (max-width: 768px) {
    width: auto;
    padding: 0;

    span {
      display: none;
    }
  }
}

.share {
  position: absolute;
  right: 59px;
  top: 130px;
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    right: 25px;
    top: 82px;
  }

  .share-text {
    min-width: 100px
  }

  button {
    background: none;
    color: #fff;
    width: 160px;
    display: flex;
    justify-content: space-between;

    @media (max-width: 768px) {
      width: auto;
      padding: 0;

      span {
        display: none;
      }
    }
  }
}

.saved-count {
  color: #fff;
  text-align: center;
  font-size: 0.875rem;
}

.saved-count-mobile {
  color: #fff;
  text-align: center;
  font-size: 0.625rem;
  margin-top: 5px;

  @media (min-width: 786px) {
    display: none;
  }
}

.tabTitle {
  & li {
    @media (min-width: 768px) {
      font-size: 1.5rem !important;
      font-family: 'AvenirNextLTPro-Regular';
      text-transform: capitalize;
      font-weight: 400;
      margin-bottom: -3px;
    }
  }
}

.image {
  height: 191px;
  width: 100%;
  background-position: center;
  background-size: cover;
}