@import '../../../styles/mixins.scss';

.panelContainer {
    display: flex;
    flex-wrap: wrap;
    flex: 1 1 25%;
    row-gap: 7px;
    align-items: center;
    justify-content: flex-start;
    padding: 8px 10px 8px 10px;
    background-color: $white;
    border-radius: 5px;
    cursor: pointer;

    .description {
        color: $black;
        font-weight: 400;
        font-family: 'AvenirNextLTPro-Regular';
        font-size: 16px;
        line-height: 25px;
        margin-block-start: 0;
        margin-block-end: 0;
        overflow: hidden;
        -webkit-line-clamp: 1;
        /* Limiting to 2 lines */
        -webkit-box-orient: vertical;
        /* For webkit-based browsers */
        text-overflow: ellipsis;
        display: -webkit-box;
    }

    .deleteBtn {

        & svg {
            fill: none;
            width: 22px;
            height: 22px;
        }
    }

    .toggleBtn {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 8px;
        right: 8px;
        z-index: 1;

        & svg {
            fill: $white;

            path {
                stroke: none;
            }
        }
    }

    .addBtn {
        & svg {
            fill: $blue;
            transform: rotate(45deg);
            width: 24px;
            height: 24px;

            path {
                stroke: $white;
            }
        }
    }

    &:hover {
        background-color: $blue;
        cursor: pointer;

        .description {
            color: $white;
        }

        .deleteBtn {
            & svg {
                path {
                    stroke: $white;
                }
            }
        }

        .addBtn {
            & svg {
                fill: $white;

                path {
                    stroke: $black;
                }
            }
        }
    }
}

.media {
    display: flex;
    width: 100%;
    position: relative;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 5px;
}

.mediaExpanded {
    position: fixed;
    background-color: rgb(0, 0, 0, 0.85);
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 9999;
}

.buttons {
    display: flex;
    gap: 12px;

    @media only screen and (orientation: portrait) {
        display: none;
    }
}

.image {
    height: 191px;
    width: 100%;
    background-position: center;
    background-size: cover;
}