@import '../../styles/mixins.scss';

.wrapper {
  display: flex;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    flex-direction: column;
  } 
}

.left {
  width: 60%;
  padding-right: 30px;

  @media (max-width: 768px) {
    width: 100%;
  } 
}

.right {
  width: 40%;

  @media (max-width: 768px) {
    width: 100%;
  } 
}

p {
  font-weight: bold;
}

h3 {
  margin-top: 0;
}

.error {
  color: red;
}

.selected-date-time {
  background: #fff;
  height: 28px;
  width: calc(100% - 20px);
  padding: 10px;
  display: flex;
  align-items: center;
  font-family: 'SpaceMono-Regular' !important;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.875rem;
}

.back {
  color: $blue;
  padding: 0;
  background: transparent;

  &:before {
    content:"\2039";
    font-size: 1.6rem;
    margin-right: 5px;
    margin-top: -2px;
  }
}

.modal-wrap {
  padding: 30px;

  @media (max-width: 768px) {
    padding: 0;
  }
}

.timeslots {
  overflow-y: scroll;
  height: 300px;
  margin-top: -10px;

  .first {
    margin-top: 0;
  }
  button {
    background: #fff;
    width: 100%;
    margin: 10px 0;
    font-family: 'SpaceMono-Regular';
    text-transform: uppercase;
    font-weight: bold;
  }
}

.active {
  background: #000 !important;
  color: #fff;
}
