.flexWrapper {
    display: flex;
    margin: 0 auto;
}

.sliderContainer {
    width: 100%;
    margin-bottom: 30px;
    position: relative;
}

.item {
    color: white;
    text-align: center;

    @media (max-width: 768px) {
      width:241px;
    }
    
    h3 {
      margin: 0;
    }
    
}

.sliderContainer :global(.slick-prev) {
    right: -35px;
}

.sliderContainer {
    :global(.slick-next) {
        right: -25px;
    }
}

.sliderContainer {
    :global(.slick-prev, .slick-next) {
        width: 40px;
        height: 40px;
        border-radius: 50%;
    }
}

.sliderContainer {
    :global(.slick-next:before) {
            color: black;
    }
}

.sliderContainer {
    :global(.slick-prev:before) {
            color: black;
    }
}


.sliderContainer {
    :global(.slick-track) {
      padding-top: 10px;
    }
}
