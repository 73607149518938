@import '../../styles/mixins.scss';

.wrapper {
  display: flex;
  justify-content: space-between;
  padding-top: 57px;
  height: calc(100% - 151px);

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 0;
    margin-top: 0;
  }
}

.content {
  @media (max-width: 768px) {
    height: calc(100% - 120px);
  }
  // opacity: 0;
  height: 100%;
  animation: fadeInContent .5s ease-in;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
}

.left {
  width: 40%;
  color: #fff;
  display: flex;
  justify-content: center;
  margin-left: 50px;

  @media (max-width: 768px) {
    width: 100%;
    margin-left: 0;
  }
}

.right {
  width: 60%;
  flex-direction: row;
  margin-right: 86px;
  height: calc(100% - 400px);

  @media (max-width: 768px) {
    width: 100%;
    height: calc(100% - 127px);
  }
}

.help {
  background-color:rgba(255, 255, 255, 0.4);
  padding: 41px 30px 26px 30px;
  border-radius: 4px;
  margin-bottom: 34px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;

  @media (max-width: 768px) {
    padding: 0 20px;
    margin-bottom: 30px;
  }
}

.lets-talk {
  position: absolute;
  right: 10px;
  top: 10px;
}

.intro {
  max-width: 413px;
  margin-right: 50px;

  h1 {
    font-size: 3.25rem;
    margin-top: 30px;
    animation: fadeInText 1s ease-in-out; /* Apply the animation here */
  }
  

  @media (max-width: 768px) {
    max-width: 100%;
    padding: 20px;

    h1 {
      margin: 0;
      font-size: 1.5rem;
      
    }

    p {
      font-size: 0.875rem;
    }
  }
}

.swiper {
  margin-top: 15px;

  @media (max-width: 768px) {
    display: none;
  }
}

.suggestions {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    display: none;
  }
  button {
    background: none;
  }
}

.suggestions-buttons {
  padding: 5px;

  @media (max-width: 768px) {
    width: 100% !important;
  }
}