@import '../../styles/mixins.scss';

.tabs {
  padding: 0px;
  height: calc(100% + 92px);

  @media (max-width: 768px) {
    padding: 20px;
    height: calc(100% - 36px) !important;
  }
}

.tab-buttons {
  padding: 10px 10px 11px 40px;

  li {
    cursor: pointer;
  }
}

.tab-content {
  width: 100%;
  height: calc(100% - 90px);

  div.item {
    display: none;
  }
}

.show {
  width: 100%;
  height: 100%;
  display: inline-block !important;
  overflow-y: auto;

  @media (max-width: 768px) {
    height: calc(100% - 50px);
  }
}

.active {
  border-bottom: 2px solid #fff;
}

.tabItem {
  opacity: 0.5;

  @media (max-width: 768px) {
    margin: 0 !important;
    padding: 0 5px;
  }
}

.tab-buttons {
  display: flex;
  border-bottom: 1px solid #fff;
  font-family: 'SpaceMono-Regular';
  text-transform: uppercase;
  font-weight: bold;
  list-style: none;
  margin: 0;
  margin-bottom: 14px;
  color: #fff;

  .list-container {
    margin-left: -40px;
    width: 100%;
    display: flex;
    letter-spacing: 1.3px;
  }

  li {
    .button {
      padding: 20px 0 10px 0;
      margin: 0 20px;
    }

    font-size: 0.625rem !important;

  }
}

@media (max-width: 768px) {
  .tab-buttons {
    font-size: 0.675rem;

    li {
      padding: 0 7px;

      .button {
        margin: 0;
      }
    }

    .list-container {
      justify-content: center;
    }
  }
}