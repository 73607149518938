input {
  width: calc(100% - 20px);
  height: 48px;
  border: none;
  padding: 0 10px;
}

label {
  font-family: 'SpaceMono-Regular';
  text-transform: uppercase;
  font-weight: bold;
  padding: 10px 0;
  width: 100%;
  display: flex;
}

.form-tip {
  font-size: 0.563rem;
  font-weight: bold;
}

textarea {
  width: 100%;
  min-height: 84px;
  border: none;
}

.form-button {
  font-family: 'SpaceMono-Regular';
  text-transform: uppercase;
  background: #010307;
  color: #fff;
  font-size: 0.875rem;
  font-weight: bold;
  margin: 20px 0;

  &:after  {
    content:"\203A";
    font-size: 20px;
    margin: -5px 0 0 5px;
  }
}

.form-error-message {
  color: red;
}