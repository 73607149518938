.generic-card-wrapper {
  background: rgba(255,255,255,0.5);
  padding: 26px 25px 14px 25px;
  border-radius: 4px;
  min-height: 100px;
  cursor: pointer;

  h4 {
    margin: 0;
    letter-spacing: 1.1px;
  }
  
  p {
    margin-bottom: 0;
    margin-top: 20px;
    line-height: 160%;
  }

  .title {
    font-family: 'SpaceMono-Regular';
    font-size: 0.875rem;
    text-transform: uppercase;
  }
}