@import "../../styles/mixins.scss";

.item-template {
  position: relative;
  border-radius: 5px;
  width: 100%;
  padding: 5px;
  background: #fff;

  &:hover {
    background: $blue;
    color: #fff;
  }

  .image {
    width: 100%;
  }

  span {
    font-size: 1rem;
    line-height: 170%;
  }
}

.innerWrapper {
  height: auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(33%, 1fr));
  row-gap: 22px;
  column-gap: 18px;
  flex: 1 1 633px;
  margin: auto;
}

.expand {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;

  svg {
    width: 36px;
  }
}

.icon {
  margin: 0 5px;
  width: 22px !important;
}

.item {
  width: 100%;
  min-height: 500px;
  border: none;
}

.footer {
  position: sticky;
  bottom: 0;
  left: 0;
  width: 100%;
}