@import '../../styles/mixins.scss';

.tabContainer {
    display: flex;
    width: fit-content;
    justify-content: flex-start;
    align-content: flex-start;
    gap: 20px;
    height: 40px;
    position: relative;

    & .tabBar {
        display: block;
        content: "";
        height: 2px;
        background-color: $black;
        position: absolute;
        bottom: 0;
        transition: left 500ms linear;
    }

    & .tab {
        display: flex;
        align-items: center;
        align-self: center;
        color: $black;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Space Mono;
        font-size: 10px;
        font-style: normal;
        font-weight: 700;
        line-height: 10px;
        padding-top: 15px;
        letter-spacing: 1.3px;
        text-transform: uppercase;
        cursor: pointer;
        position: relative;

        & span {
            font-family: 'SpaceMono-Regular';
        }

        & .large {
          font-family: 'AvenirNextLTPro-Regular';
            font-size: 24px;
            font-style: normal;
            text-transform: initial;
            font-weight: 400;
            line-height: 140%;
        }

        & sup {
            position: absolute;
            top: 4px;
            right: -8px;
            color: $black;
            text-align: center;
            font-family: 'SpaceMono-Regular';
            font-size: 6px;
            font-style: normal;
            font-weight: 700;
            letter-spacing: 1.3px;
        }
    }
}