@import "../../../styles/mixins.scss";

.viewPortWidth {
  @media only screen and (orientation: portrait) {
    width: calc(100vw - 42px);
    overflow-x: scroll;

    &::-webkit-scrollbar {
      display: none; /* Hide scrollbar for Chrome, Safari, etc. */
    }
  }
  @media (max-width: 767px) {
    padding-bottom: 64px;
  }
}

.container {
  display: flex;
  width: auto;
  justify-content: space-between;
  column-gap: 8px;
  position: relative;
}

.scrollContainer {
  flex-grow: 1;
  overflow: hidden;
  transform: translateX(0px);
  width: 90%;
  position: relative;
  touch-action: none;
  mask-image: linear-gradient(
    90deg,
    rgba(0, 0, 0, 1) 94%,
    rgba(0, 0, 0, 0.8) 96%,
    rgba(0, 0, 0, 0) 100%
  );

  @media screen and (max-width: 834px) {
    width: auto;
    flex: 0 0 auto;
  }
}

.scroll {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  transition: transform 0.2s;
  touch-action: none;
  display: flex;
  white-space: nowrap;
  padding: 10px 0px;
}

.btn {
  margin-right: 8px;
}

.controls {
  flex: 0 0 auto;
  display: flex;
  gap: 8px;
  padding-top: 10px;

  @media screen and (max-width: 834px) {
    display: none;
  }
}

.icon {
  & svg {
    fill: none;
    color: $white;
    width: 1.5rem;
    height: 1.5rem;
  }
}

.feather {
  width: 40px;
  height: 40px;
  right: 64px;
  top: 10px;
  position: absolute;
  // background: linear-gradient(to right,  rgba(235,239,244,0) 0%,rgba(235,239,244,1) 100%);
  mask-image: linear-gradient(
    90deg,
    rgba(0, 0, 0, 1) 94%,
    rgba(0, 0, 0, 0.8) 96%,
    rgba(0, 0, 0, 0) 100%
  );
}
