@import '../../styles/mixins.scss';

.help {
    background-color:rgba(255, 255, 255, 0.3);
    padding: 50px 30px 30px 30px;
    
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  
  .help-input {
    width: 85%;
    position: relative;
    width: 100%;    display: flex;

    & form {
      overflow: hidden;
    }
  
    h3 {
      margin: 15px 0px;
    }
  }
  
  .intro {
    max-width: 413px;
  }
  
  .input {
    display: initial;
    border: 0;
    background: transparent;
    font-family: 'AvenirNextLTPro-Regular';
    font-size: 1.5rem;
    padding: 9px 0 0 0;
    margin: 0;
    outline: none;
    align-items: center;
    position: relative;
    margin-left: 10px;
    border-bottom: 1px solid #000;
    width: calc(100% - 75px);

    @media (max-width: 768px) {
      margin-bottom: 20px;
      width: 100%;
    }
  
    &::placeholder {
      color: $nav-grey;
      opacity: 1;
    }
  }

  form {
    width: 100%;
  }

  .buttons {
    width: auto;
    position: absolute;
    top: 11px;
    right: 60px;
    display: flex;

    @media (max-width: 768px) {
      right: 0;
    }
  
    button {
      padding: 5px;
      background: none;
    }
  }
  
  .talk {
    margin-top:13px;
    margin-right: 10px;
    width: 40px;
  }

  .disabled {
    opacity: 0.25;
  }