@import '../../styles/mixins.scss';

.btn {
    background: none;
    border: none;
    padding: 0;
    font-family: inherit;
    text-decoration: underline;
    cursor: pointer;

    &:focus,
    &:active {
        outline: none;
        border: none;
    }

    &:hover {
        opacity: 0.8;
    }
}

.btnDisabled {
    &:hover {}
}

.inactive {
    opacity: 0.4;

    &:hover {
        opacity: 0.4;
    }
}

.btnIcon {
    & svg {
        width: 40px;
        height: 40px;
        fill: #fff;
        color: $white;
        z-index: 0;
    }
}