.bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    right: 36px;
    top: 36px;
  }
  
  .bm-burger-bars {
    background: #373a47;
  }
  
  .bm-burger-bars-hover {
    background: grey;
  }
  
  .bm-cross-button {
    height: 24px;
    width: 24px;
    right: 24px !important;
    position: absolute;
  }
  
  .bm-cross {
    background: #fff;
  }
  
  .bm-menu-wrap {
    position: fixed;
    height: 100%;
  }
  
  .bm-menu {
    background: black;
  }
  
  .bm-morph-shape {
    fill: #373a47;
  }
  
  .bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
    height: auto !important;
  }
  
  .bm-item {
    display: inline-block;
  }
  
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }