@import '../../styles/mixins.scss';

.pagination {
  display: flex;
  justify-content: flex-end;
  column-gap: 12px;
}

.disabled {
  // opacity: 0.6;
}

.selection {
  color: #fff;
  font-weight: bold;
  font-size: 12px;
  padding-top: 8px;
  font-family: 'SpaceMono-Regular';
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 2px;
}


.btn {
  & svg {
    width: 30px;
    height: 30px;
    color: $white;

    path {
      stroke: #fff;
    }
  }
}

.itemDisabled {
  color: #fff;

  & svg {
    opacity: 0.3;
  }
}