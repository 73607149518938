@import '../../styles/mixins.scss';

.conversationWrapper {
  height: calc(100% - 60px);
  position: relative;
  background-size: 26px 26px;
  opacity: 1px solid red;

  video {
    width: 100%;
  }

  iframe {
    width: 100%;
    height: 100%;
    border: none;
  }

  object {
    width: 100%;
    height: 100%;
  }

  :global(.slick-slide){
    div {
      height: 100%;
    }
  }

  :global(.slick-track){
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
    gap: 0;
    margin: 0;
  }
}

.card {
  width: 100%;
  height: 100%;
}

.video {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.grid {
  background-image:
  linear-gradient(to right, rgb(224, 224, 224, 0.5) 1px, transparent 1px),
  linear-gradient(to bottom, rgb(224, 224, 224, 0.5) 1px, transparent 1px);
}
