@import '../../styles/mixins.scss';

.main-app-layout {
  height: inherit;
}

.header {
  width: 100%;
  height: 70px;
  display: flex;
  justify-content:flex-end;
}

.logo-wrapper {
  margin-top: 15px;
  margin-left: 50px;
}

.nav {
  margin-right: 100px;
  width: 600px;
}

.logo {
    height: 18px;
    margin-left: 14px;

    @media (min-width: 768px) {
        height: 39px;
        margin-left: 40px;
    }

    @media (min-width: 1200px) {

    }

    &:hover {
        text-decoration: none;
    }

    img.logo {
        height: 100%;
        width: auto;
    }
}


.btn {
    margin: 0 12px;
    padding: 0;
    border: none;
    background: none;
    outline: none;
    cursor: pointer;

    @media (min-width: 768px) {
        margin: 0 20px;
    }
    
    @media (min-width: 1200px) {

    }
}

.history-btn {
    width: auto;
    height: 17px;

    @media (min-width: 768px) {
        height: 27px;
    }
    
    @media (min-width: 1200px) {
        height: 13px;
    }
}

.conversation-btn { /* BD added for testing until we get design */
    width: auto;
    height: 17px;

    @media (min-width: 768px) {
        height: 27px;
    }
    
    @media (min-width: 1200px) {
        height: 13px;
    }
}

.saved-btn {
    width: auto;
    height: 15px;

    @media (min-width: 768px) {
        height: 25px;
    }

    @media (min-width: 1200px) {
        border-radius: 5px;
        border: 1px solid #A7A7A7;
        width: 74px;
    }

    img {
        @media (min-width: 1200px) {
            position: relative;
            top: -1px;
            height: 10px;
        }
    }
}

.hamburger-menu-options {
    list-style: none;
    font-size: 1.25rem;
    color: white;
    font-family: 'SpaceMono-Regular';
    text-transform: uppercase;
}

.item {
    color: white;
    text-decoration: none;
    float: left;
    width: 100%;
    padding: 10px 0;
}

@media (max-width: 768px) {
    .nav {
      width: 100%;
      margin-right: 0;
      margin: 10px 20px;
    }
}
