@import '../../../styles/mixins.scss';

.htmlViewer {
  width: 100%;
  height: 100%;

  embed {
    height: 100%;
  }

  #document {
    width: 100%;
    height: 100%;
  }
}

.loading {
  display: none !important;
}