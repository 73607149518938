.quarter {
  width: calc(25% - 30px);
  margin: 10px;

  @media screen and (max-width: 768px) {
    width: calc(50% - 30px);
  }

  @media screen and (max-width: 768px) {
    width: calc(100% - 30px);
  }
}

.shared-item {
  display: flex;
  flex-direction: column;
  background: transparent;
  position: relative;
}

.shared-item-conversation {
  display: flex;
  flex-direction: column;
  background: transparent;
  position: relative;
  background: #fff;
  padding: 10px;
  min-height: 213px;

  .question {
    color: #000;
    text-align: left;;
  }
}

.img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.remove {
  position: absolute;
  top: -16px;
  right: -5px;

  button {
    padding: 0;
    background: none;
  }

  & svg {
    width: 22px;
    height: 22px;
  }
}

.image {
  width: 100%;
  height: 141px;
  flex-shrink: 0;
}


.question {
  font-family: 'SpaceMono-Regular';
  text-transform: uppercase;
  font-size: 0.563rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.answer {
  padding-top: 8px;
  font-size: 0.625rem;
  line-height: 160%;
  color: var(--Black, #010307);

  font-family: 'AvenirNextLTPro-Regular';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
}