.hamburger-menu-options {
  list-style: none;
  font-size: 1.25rem;
  color: white;
  font-family: 'SpaceMono-Regular';
  text-transform: uppercase;
}

.item {
  color: white;
  text-decoration: none;
  float: left;
  width: 100%;
  padding: 10px 0;
}