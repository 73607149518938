/* Global Stylesheet */

body {
  font-family: "Lato", sans-serif;
}

h1 {
  font-size: 3.75rem;
  font-family: "AvenirNextLTPro-Regular";
  font-weight: 400;
}

h2 {
  font-size: 2.625rem;
  font-family: "AvenirNextLTPro-Regular";
  font-weight: 400;
}

h3 {
  font-size: 1.5rem;
  font-family: "AvenirNextLTPro-Regular";
  font-weight: 400;
}

h4 {
  font-family: "SpaceMono-Regular";
  font-size: 0.875rem;
  text-transform: uppercase;
}

h5 {
  font-family: "SpaceMono-Regular";
  text-transform: uppercase;
}

h6 {
  font-size: 1.25rem;
  font-family: "AvenirNextLTPro-Regular";
}

p {
  font-size: 1rem;
  font-weight: 100;
  font-family: "AvenirNextLTPro-Regular";
}

.smalltext {
  font-size: 0.875rem;
  font-family: "AvenirNextLTPro-Regular";
}

.rotate {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

/* Slick slider overrides */
.slick-list,
.slick-track,
.slick-slider,
.slick-slide {
  height: calc(100% - 2px) !important;
}

.slick-track {
  display: flex;
  gap: 15px;
}

.full-width {
  width: 100%;
}

/* React modal overlay */
.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 300ms ease-in-out;
  background: rgba(0, 0, 0, 0.7) !important;
  z-index: 9999;

  @media (max-width: 768px) {
    height: 100%;
  }
}

.ReactModal__Content {
  max-height: 70%;
  overflow-y: auto;
  margin: 50px 0;

  @media (max-width: 768px) {
    overflow-y: auto;
    height: 90%;
    width: 80%;
  }

  max-width: 700px;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.show {
  display: inline-block;
}

.moible-nav {
  z-index: 1;
}

// React calendar
.react-calendar {
  border: none;

  @media (max-width: 768px) {
    width: 100%;
  }
}

// React slick overrides
.slick-track {
  display: flex;
  align-items: center;
}

.slick-dots {
  display: flex !important;
  justify-content: end !important;
}

.hide {
  display: none;
}

.letter-spacing {
  letter-spacing: 2px;
}

@media (max-width: 768px) {
  .slick-track {
    width: 100vw !important;
    gap: 0;
  }

  .slick-list {
    width: 100%;
  }

  .slider {
    width: 100vw;
  }

  .slick-slide {
    width: 100vw !important;
  }
}

.bm-burger-button {
  top: 25px;
  right: 20px;
  color: white;
  position: absolute;
}

// Burger menu overrides
.bm-burger-bars {
  @media (max-width: 768px) {
    background: #fff;
    z-index: 0;
  }
}


// Toastify overrides
.Toastify__progress-bar-theme--light {
  background: #1960AE !important;
}

.Toastify__toast {
  background: black !important;
  height: 75px;
  width: 332px;
  color: #fff;
  right: 20px !important;
  box-shadow: 0px 0px 12px 0px #006EE8;
  margin-right: 0;
}

.Toastify__toast div div {
  background: #000;
  color: #fff;
}

// Chat typing back css animation
.typing-container {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.typing {
  width: 3em;
  height: 0.5em;
  position: relative;
  padding: 10px;
  margin-left: 5px;
  background: #e6e6e6;
  border-radius: 20px;
  display: flex;
  justify-content: center;
}

.typing__dot {
  float: left;
  width: 8px;
  height: 8px;
  margin: 0 4px;
  background: #8d8c91;
  border-radius: 50%;
  opacity: 0;
  animation: loadingFade 1s infinite;
}

.typing__dot:nth-child(1) {
  animation-delay: 0s;
}

.typing__dot:nth-child(2) {
  animation-delay: 0.2s;
}

.typing__dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes loadingFade {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.8;
  }

  100% {
    opacity: 0;
  }
}

input::placeholder {
  color: #fff;
}