.wrapper {
    display: flex;
    height: 100%;
    align-items: stretch;
    font-family: 'AvenirNextLTPro-Regular';
    /* height: calc(100% - 128px); */
    height: calc(100vh - 140px);
    

    @media (max-width: 768px) {
      height: calc(100% - 135px);
      margin: 0px 20px;
      gap: 0
    }
}

.left {
    width: 50%;
    padding: 30px 30px 0 46px;
    height: calc(100% - 45px);
    align-items: stretch;
    position: relative;

    @media (max-width: 768px) {
      width: 100% !important;
      padding: 0;
    } 
}

.right {
    width: calc(50% - 60px);
    position: relative;
    align-items: stretch;
    padding: 30px 30px 0 30px;
    height: calc(100% - 30px);

    @media (max-width: 768px) {
      width: 100% !important;
      padding: 0;
    } 
}

.responsiveHidden {
  @media (max-width: 768px) {
    display: none;
  } 
}